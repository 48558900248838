
























import {
  Vue,
  Component,
  PropSync,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/utils/screen';

declare global {
  interface Window {
    Calendly: any;
  }
}

@Component({ name: 'CalendlyModal' })
export default class CalendlyModal extends Vue {
  @PropSync('isOpen', { type: Boolean })
  is_open!: boolean;

  @Prop(String)
  url!: string;

  @Prop({ default: () => ({}) })
  prefill!: Record<string, string>;

  small_screen: null | boolean = null;

  isCalendlyEvent(event: MessageEvent) {
    return event.data.event && event.data.event.indexOf('calendly') === 0;
  }

  handleCalendlyEvent(event: MessageEvent) {
    if (this.isCalendlyEvent(event)) {
      if (event.data.event === 'calendly.event_scheduled') {
        this.$emit('success');
      }
    }
  }

  @Watch('is_open')
  handleOpen(is_open: boolean) {
    if (is_open) {
      setTimeout(() => {
        window.Calendly.initInlineWidget({
          url: this.url,
          parentElement: document.getElementById('calendly-modal'),
          prefill: this.prefill,
        });
      }, 1000);

      window.addEventListener('message', this.handleCalendlyEvent);
    } else {
      window.removeEventListener('message', this.handleCalendlyEvent);
    }
  }

  created() {
    this.small_screen = getScreenSizeVariant() === 'small';
    const calendly_script = document.createElement('script');
    calendly_script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    document.head.appendChild(calendly_script);
    this.handleOpen(this.is_open);
  }
}

