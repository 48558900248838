import { v4 } from 'uuid';
import TYPES from '@/types';
import store from '@/vue-app/store';

// Application
import GetPersonQueryService
  from '@/modules/onboarding/person/application/queries/get-person-query-service';
import CreateOnBoardingInterviewCommand
  from '@/modules/onboarding/interviews/application/commands/create-on-boarding-interview-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import OnBoardingSearchCustomerEmailsQuery
  from '@/modules/onboarding/customer-email/application/queries/on-boarding-search-customer-emails-query';
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';

export default class ValidationProcessViewModel {
  @Inject(TYPES.GET_PERSON_QUERY_SERVICE)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.CREATE_ON_BOARDING_INTERVIEW_COMMAND)
  private readonly create_on_boarding_interview_command!: CreateOnBoardingInterviewCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnboardingStepCommand;

  @Inject(TYPES.ON_BOARDING_SEARCH_CUSTOMER_EMAILS_QUERY)
  private readonly on_boarding_list_emails_query!: OnBoardingSearchCustomerEmailsQuery;

  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  private readonly get_internet_status_query!: GetInternetStatusQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  has_error = false;

  modal_is_open = false;

  get calendly_url() {
    return process.env.VUE_APP_CALENDLY_URL;
  }

  get calendly_prefilled_data() {
    const { name } = this.get_person_query.execute();
    const email_entity = this.on_boarding_list_emails_query.execute().find(
      (item) => item.customer_email_default,
    );
    let email = '';

    if (email_entity) {
      email = email_entity.email.email_address;
    }

    return {
      name,
      email,
    };
  }

  get internet_status() {
    return this.get_internet_status_query.execute();
  }

  constructor() {
    this.showGoToSiteOnHeader();
    this.get_person_query.execute();
    this.on_boarding_list_emails_query.execute();
  }

  openModal = () => {
    this.modal_is_open = true;
  }

  onInterviewScheduled = async () => {
    const {
      name,
      last_name,
      second_last_name,
    } = this.get_person_query.execute();
    await this.create_on_boarding_interview_command.execute({
      id: v4(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      customer_id: sessionStorage.getItem('user_id')!,
      name,
      last_name,
      second_last_name,
      status: 'pending',
      promotor_id: 'ERR',
    });
    await this.update_on_boarding_step_command.execute({
      id: this.current_step.id,
      current_step: this.current_step.current_step,
      payload: {
        is_valid: false,
        rescheduled: true,
      },
    });

    this.has_error = false;
    this.modal_is_open = false;
  }

  initialize = async () => {
    await store.dispatch('layout/toggleGoToSiteOnHeader', false);
    this.current_step = await this.get_on_boarding_step_query.execute('validation_process');
    this.setHasErrorAccordingToStepsData();
  }

  private setHasErrorAccordingToStepsData() {
    if (!this.current_step.payload.is_valid && !this.current_step.payload.rescheduled) {
      this.has_error = true;
    }
  }

  private showGoToSiteOnHeader() {
    store.dispatch('layout/toggleGoToSiteOnHeader', true);
  }
}
