




































import { Vue, Component } from 'vue-property-decorator';
import CalendlyModal from '@/vue-app/components/calendly/CalendlyModal.vue';
import ValidationProcessViewModel
  from '@/vue-app/view-models/components/on-boarding/registration-status/validation-process-view-model';

@Component({
  name: 'ValidationProcess',
  components: { CalendlyModal },
})
export default class ValidationProcess extends Vue {
  validation_process_view_model = Vue.observable(new ValidationProcessViewModel());

  async created() {
    await this.validation_process_view_model.initialize();
  }
}
